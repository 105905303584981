.column-chart {
    width: 100%;
    box-shadow: 0px 0px 15px 5px rgba(72, 198, 223, 0.3) !important;
}

.column-chart:hover {
    transform: translateY(15px);
    filter: drop-shadow(-20px 0 10px rgba(0, 0, 0, 0.1));
    transition: 0.6s ease;
    cursor: pointer;
}

.more-icon {
    display: none;
}

.recycle-reclaimed-chart {
    margin-top: 2%;
    border: 1px solid black;
    width: 80%;
    height: 370px;
    background-color: rgba(141, 236, 126, 0.337);
}