body {
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
}

.dialog-body {
  box-shadow: 0px 3px 3px 0px rgb(52, 218, 74) inset, 0px -5px 3px -3px;
  /* box-shadow: 0 -5px 3px -3px rgb(52, 218, 74), 0 5px 3px -3px rgb(52, 218, 74); */
}

label {
  font-size: 18px !important;
}

/* .css-cie5r9-MuiFormLabel-root-MuiInputLabel-root{
  font-size: 18px !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.answer-text {
  width: 614px;
  overflow-wrap: break-word;
}

.break-long-text {
  word-wrap: break-word;
  /* Break words if they are too long */
  overflow-wrap: break-word;
  /* Ensure that long words or URLs are broken properly */
  white-space: pre-line;
  /* Preserve newline characters and break on spaces */
  height: 200px;
  overflow-x: scroll;
}

@media only screen and (max-width: 992px) {
  .answer-text {
    /* width: 750px; */
    overflow-wrap: break-word;
  }
}

@media only screen and (min-width: 1200px) {
  .answer-text {
    width: 805px;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 1400px) {
  .answer-text {
    width: 514px;
    overflow-wrap: break-word;
  }
}

@media only screen and (max-width: 1300px) {
  .answer-text {
    width: 750px;
    overflow-wrap: break-word;
  }
}

/* .headingText {
  text-transform: capitalize;
} */

canvas {
  height: 300px !important;
  width: 100% !important;
  font-size: 10px !important;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  top: 0;
  z-index: 9999;
}

