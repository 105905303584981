.bg-image {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/ESG-bg-image.jpg");
    background-size: 100vw 100vh;
    background-position: center;
}

.login-form-body {
    margin-top: 30% !important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.pass-wrapper {
    position: relative;
    /* display: flex; */
    margin-bottom: 14px;
}

.icon {
    position: absolute;
    top: 30%;
    right: 2%;
    cursor: pointer;
}

/* .login-form-background {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/ESG-bg-image.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
} */