.active-text {
    color: rgb(3, 173, 29);
    text-decoration: none;
    position: relative;
    /* border: 0.5px rgb(79, 77, 77);
    border-radius: 10px;
    background-color: rgb(66, 212, 139);
    padding: 10px; */
    /* padding-right: 28%; */
    /* margin-left: 10px; */
    background-size: cover;

    .display-icon {
        display: inline;
    }
}

.inactive-text {
    text-decoration: none;
    color: rgb(79, 77, 77);

    .display-icon {
        display: none;
    }
}

.side-nav {
    /* margin-top: 50px !important; */
    height: 100%;
    overflow-y: hidden;
}

.nav-body {
    margin-top: 25px;
}