.PhoneInputInput {
    /* margin-top: 21px; */
    height: 40px !important;
    border-width: 1px;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.PhoneInputInput:focus,
.PhoneInputInput:hover {
    outline: none;
    height: 40px;
    border-width: 2px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.break-cell {
    table-layout: fixed;
    word-break: break-all;
}